
    
    display: inline;
    margin: 0;
    padding: 0;

    
    border: none;

    
    color: var(--matterColor);

    
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  