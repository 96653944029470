
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  